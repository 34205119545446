<div class="box">
    <p class="header">{{propertyCount}} Results</p>
    <div class="custom-filter-styles">
        <app-filter [filterObject]="filterObject" (searchEvent)="searchProperty($event)"
            (toggleFavouriteEvent)="handleToggleFavourite($event)"></app-filter>
    </div>
    <div [ngClass]="{'gap-logged-out': !checkLoginStatus, 'gap-logged-in': checkLoginStatus}" class="property-card"
        infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="300" [scrollWindow]="false"
        (scrolled)="onScroll()">
        <div *ngFor="let value of propertyList">
            <div class="card img-cards"
                [ngClass]="{'img-card-small': !checkLoginStatus, 'img-card-medium': checkLoginStatus}">
                <fa-icon [icon]="faHeart" class="favorite-icon" [ngClass]="{'favorite': value.favProperty}"
                    (click)="toggleFavorite($event, value)" *ngIf="!isAdmin" [ngbTooltip]="'Save'">
                </fa-icon>
                <ngb-carousel #carousel [interval]="2000" [showNavigationIndicators]="false"
                    [showNavigationArrows]="value.propertyDocs?.length > 1">
                    <ng-template *ngFor="let item of value.galleryPics; index as imageIndex" ngbSlide>
                        <div class="image-container">
                            <img class="carousel-image" src="{{item.objectUrl}}" alt="..." width="100%" height="115px">
                        </div>
                    </ng-template>
                </ngb-carousel>
                <div class="card-body">
                    <p class="title" title="{{value.projectName}}">{{value.projectName}}</p>
                    <div class="edit-button" *ngIf="isAdmin">
                        <app-common-button buttonClass="smallbuttoncancel" buttonLabel="Edit"
                            (click)="onPropertyEditClicked(value._id)"></app-common-button>
                    </div>
                    <p class="size"> Size: <span class="size">{{value.minimumSize.value }}</span>
                        <span class="size prop_size">{{ value?.minimumSize.unitType}}</span>
                        <span *ngIf="value.units?.length > 0"> Onwards</span>
                    </p>
                    <p class="price">
                        <span *ngIf="value.units?.length > 0">  ₹ {{ value?.minimumPrice }} Onwards</span>
                        <span *ngIf="value.units?.length===0"> ₹ {{getTotalAsset(value?.minimumPrice,value?.size?.value) }}</span>
                    </p>
                    <p class="location">Location: <span
                            class="location" ngbTooltip="{{value.address.locality}},{{value.address.city}}">{{value.address.locality}},{{value.address.city}}</span></p>
                    <div class="buttons">
                        <div>
                            <app-common-button buttonClass="view-project" buttonLabel="View Project"
                                (clickEvent)="openProjectView(value)"></app-common-button>
                        </div>
                        <div class="enquiry" *ngIf="!isAdmin">
                            <app-common-button buttonClass="view-enquiry" buttonLabel="Enquiry"
                                (clickEvent)="openEnquiry(value)"></app-common-button>
                        </div>
                        <div *ngIf="isAdmin && checkLoginStatus">
                            <app-common-button buttonClass="view-enquiry"
                                buttonLabel="Enquiry List ({{value.enquiresCount || 0}})"
                                (clickEvent)="openEnquiryList(value)"></app-common-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="verifyLoader" class="loader">
            <app-common-icon [loadingIcon]="verifyLoader"></app-common-icon>
        </div>
    </div>
</div>
<app-enquiry-popup [propertyId]="selectedPropertyId" [isEnquiry]="isEnquiry"
    (closeModal)="closeEnquiry()"></app-enquiry-popup>
<div *ngIf="isEnquiryList">
    <app-enquiry-list [isEnquiryList]="isEnquiryList" [propertyId]="selectedPropertyId"
        (closeModal)="closeEnquiryList()"></app-enquiry-list>
</div>
<div *ngIf="!checkLoginStatus">
    <app-login-popup [isVisible]="isLoginModalVisible" (closeModal)="closeProjectModal()"></app-login-popup>
</div>