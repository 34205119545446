import { Component, ElementRef, HostListener, SimpleChanges, ViewChild } from '@angular/core';
import { faArrowLeft, faBuilding, faDownload, faExpand, faHeart, faShareNodes, faTowerObservation } from '@fortawesome/free-solid-svg-icons';
import { PropertyService } from '../../services/property/property.service';
import { PriceFormatterService } from '../../services/price-formatter/price-formatter.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { StorageService } from '../../services/storage/storage.service';
import { StorageKeys } from '../share/enums/storage.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const GOOGLE_MAPS_API_KEY = environment.MAPS_KEY;

@Component({
  selector: 'app-new-property-details',
  templateUrl: './new-property-details.component.html',
  styleUrl: './new-property-details.component.scss'
})
export class NewPropertyDetailsComponent {
  public readonly faTower = faTowerObservation;
  public readonly faFloor = faBuilding;
  public readonly faHeart = faHeart;
  public readonly faShare = faShareNodes;
  public readonly faDownload = faDownload;
  public readonly faBack = faArrowLeft;
  public readonly faExpand = faExpand;

  selectedImage: string = '';
  images: any[] = [];
  headerImage: any[] = [];
  floorPlan: any[] = [];
  propertyId: any;
  verifyLoader: boolean = false;
  projectDetails: any;
  units: any;
  amenities:any []=[];
  minPrice: any;
  maxPrice: any;
  minimumPrice: any;
  maximumPrice: any;
  totalAssetValue:any;
  vicinity: any;
  video: any;
  urlSafe!: SafeResourceUrl;
  mapUrl!: SafeResourceUrl;
  selectedPropertyId: any;
  isEnquiry: boolean = false;
  status: any;
  isAdmin: boolean = false;
  isLoginModalVisible: boolean = false;
  @ViewChild('documentsSection') documentsSection!: ElementRef;
  @ViewChild('amenitiesSection') amenitiesSection!: ElementRef;
  modalImage: string = '';
  visibleAmenities : any;
  showMoreAmenities : any;
  isMobile = false;

  constructor(
    private propertyService: PropertyService,
    private activatedRoute: ActivatedRoute,
    public priceFormatterService: PriceFormatterService,
    private sanitizer: DomSanitizer,
    private storageService: StorageService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.status = this.storageService.getDataFromLocalStorage(
      StorageKeys.enrollmentType
    );
    if (this.status === 'Admin') {
      this.isAdmin = true;
    }
  }

  ngOnInit() {
    this.checkViewportSize();
    this.getLocalStorageDetails();
    this.activatedRoute.queryParams?.subscribe((res: any) => {
      this.selectedPropertyId = res?.pid;
      this.showPropertyDetails(res?.pid);
    })
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  selectImage(image: any) {
    this.selectedImage = image?.objectUrl;
  }

  showPropertyDetails(id: any) {
    this.propertyService.getsubPropertyList(id).subscribe({
      next: (res: any) => {
        if (res && res.data) {
          this.projectDetails = res?.data;
          this.units = res?.data?.units;
          this.vicinity = res?.data?.vicinity;
          this.amenities = res?.data?.advanceFeatures?.amenity;
          this.visibleAmenities = this.amenities.slice(0, 2); 
          this.showMoreAmenities = this.amenities.slice(2);
          console.log(this.showMoreAmenities) 
          this.images = res?.data?.galleryPics;
          this.headerImage = res?.data?.headerSectionPhotos;
          this.floorPlan = res?.data?.floorPlan;
          this.selectedImage = this?.images?.length > 0 ? this?.images[0]?.objectUrl : '';
          this.video = this.processVideos(res?.data?.video);
          this.projectDetails.startingPrice = this.priceFormatterService.formatPrice(this.projectDetails.startingPrice);
          this.maximumPrice = this.priceFormatterService.formatPrice(this.projectDetails.maximumPrice);
          this.minimumPrice = this.priceFormatterService.formatPrice(this.projectDetails.minimumPrice);
          this.totalAssetValue = this.priceFormatterService.formatPrice(this.minimumPrice * this.projectDetails?.size?.value);

          if (this.projectDetails?.address?.location) {
            const [latitude, longitude] = this.projectDetails.address.location;
            const googleMapsUrl = `https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=${latitude},${longitude}`;
            this.mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(googleMapsUrl);
          }
          this.verifyLoader = false;
        }
      },
      error: (error: any) => {
        this.verifyLoader = false;
        console.error('Error fetching property details:', error);
      }
    });
  }

  openEnquiry() {
    if (!this.checkLoginStatus) {
      this.isLoginModalVisible = true;
    } else {
      if (this.selectedPropertyId) {
        this.isEnquiry = true;
      }
    }
  }

  openProjectModal() {
    this.isLoginModalVisible = true;
  }

  closeProjectModal() {
    this.isLoginModalVisible = false;
    this.getLocalStorageDetails();
  }


  get checkLoginStatus() {
    if (localStorage.getItem('token')) {
      return true;
    } else {
      return false;
    }
  }

  closeEnquiry() {
    this.isEnquiry = false;
  }

  processVideos(videos: any[]): any[] {
    return videos.map(video => {
      let videoId: string | undefined;
      if (video.includes('/embed/')) {
        videoId = video.split('/embed/')[1];
      } else if (video.includes('v=')) {
        videoId = video.split('v=')[1];
      }
      if (!videoId) {
        console.error('Invalid video URL:', video);
        return { urlSafe: null };
      }
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      return {
        urlSafe: this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl)
      };
    });
  }

  getLocalStorageDetails() {
    this.status = this.storageService.getDataFromLocalStorage(
      StorageKeys.enrollmentType
    );
    if (this.status === 'Admin') {
      this.isAdmin = true;
    }
  }

  scrollToDocuments() {
    if (this.documentsSection) {
      this.documentsSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToAmenities() {
    this.amenitiesSection.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  downloadAllBrochures(key: string, type = 'brochure') {
    if (this.projectDetails?.[key]?.length > 0) {
      for (let [index, brochure] of this.projectDetails[key].entries()) {
        this.downloadFiles(brochure.objectUrl, this.projectDetails.projectName + "__" + type + (index + 1));
      }
    } else {
      console.error('No brochures available to download.');
    }
  }

  getMaxFloors(projectDetails: any): string {
    if (projectDetails?.numberOfTowers > 1) {
      const maxFloors = Math.min(...projectDetails.towers.map((tower: any) => tower.value));
      return maxFloors + '+ Floors';
    } else if (projectDetails?.numberOfTowers === 1) {
      return projectDetails.towers[0].value + ' Floors';
    }
    return '';
  }

  getTowers(projectDetails: any): string {
    if (projectDetails?.numberOfTowers > 1) {
      return projectDetails.numberOfTowers + ' Towers';
    } else if (projectDetails?.numberOfTowers === 1) {
      return '1 Tower';
    }
    return '';
  }  

  async downloadFiles(url: any, title: string) {
    this.propertyService.downloadFile(url, title).subscribe((response: any) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(response?.body);
      link.download = response.headers.get('file-name');
      document.body.appendChild(link);
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      link.remove();
      window.URL.revokeObjectURL(link.href);
    });
    // let a: any = document.createElement('a');
    // a.href = url;
    // a.target = '_parent';
    // a.download = 'Crowd-India-people-.jpg';
    // (document.body || document.documentElement).appendChild(a);
    // a.click();
    // a.parentNode.removeChild(a);
    // url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // console.log("-------", url);
    //   url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    //   console.log("url--", url);
    //   const myHeaders = new Headers();
    //   myHeaders.append("Content-Type", "image/png");
    //   fetch(url, {
    //     mode: 'no-cors',
    //     headers: myHeaders
    //   }).then(response => {
    //     var filename = response.headers.get('Content-Type');
    //     console.log("type---",filename);
    //     return response.blob()
    //   }).then( data => {
    //       let blob = new Blob([data]);
    //       var url = window.URL.createObjectURL(data);
    //       var a = document.createElement('a');
    //       a.href = url;
    //       a.download = "filename.png";
    //       document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    //       a.click();
    //       a.remove();
    //       //window.location.assign(file);
    //     });
    //   return;
    //   this.propertyService.downloadFile(url).subscribe({
    //     next: (res: any) => {
    //       console.log(res)
    //     },
    //     error: (error: any) => {
    //       console.log(error)
    //     }
    //   }
    //   )
  }

  toggleFavorite(event: Event): void {
    event.stopPropagation();
    if (this.checkLoginStatus) {
      this.projectDetails.favProperty = !this.projectDetails.favProperty;
      this.propertyService.selectFavorite(this.selectedPropertyId).subscribe({
        next: (res: any) => {
          this.verifyLoader = false;
        },
        error: (error: any) => {
          console.error("Error toggling favorite status:", error);
        }
      });
    }
  }


  back() {
    this.router.navigate(['properties-list']);
  }

  openModal(image: string, content: any): void {
    this.modalImage = image;
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  @HostListener('window:resize')
  checkViewportSize() {
    const width = window.innerWidth;
    this.isMobile = width < 768;
  }
}
