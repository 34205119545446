<div class="container">
  <!-- <ngb-carousel #carousel [interval]="3000" [showNavigationIndicators]="false"
      [showNavigationArrows]="headerImage && headerImage.length > 0">
      <ng-template *ngFor="let image of headerImage" ngbSlide>
          <div class="image" [ngClass]="{'carousel-image-sub': isAdmin !== true}">
              <img class="carousel-image" src="{{image?.objectUrl}}" alt="...">
          </div>
      </ng-template>
  </ngb-carousel> -->
  <div class="row">
    <div class="container">
        <div class="col-md-12">
            <!-- <div class="prop-price container">
                <fa-icon [icon]="faBack" class="back-icon" [ngbTooltip]="'Back'" (click)="back()"
                    *ngIf="!isAdmin"> </fa-icon>
                <h2 class="price-number" *ngIf="units?.length>0"> ₹{{minimumPrice}}-{{maximumPrice}}</h2>
            </div> -->
        
    <div class="gallery-top" [ngClass]="{'gallery-top1': isAdmin === true}">
   
    <div class="col-md-6 pull-left">
    <!-- <div class="heading">
        <p class="heading-text">GALLERY</p>
    </div> -->
    <div class="image-gallery" *ngIf="!isMobile">
        <div class="main-image" *ngIf="selectedImage && selectedImage.length > 0">
            <img [src]="selectedImage" alt="Selected Image" [ngClass]="{'property-details': isAdmin !== true}">
            <div class="action-buttons" [ngClass]="{'action-buttons1': isAdmin === true}">
                <fa-icon [icon]="faExpand" class="expand" (click)="openModal(selectedImage, imageModal)"></fa-icon>
                <button class="btn save" *ngIf="!isAdmin && checkLoginStatus">
                    <fa-icon [icon]="faHeart" class="favorite-icon-save"
                        [ngClass]="{'favorite': projectDetails?.favProperty}" [ngbTooltip]="'Save'"
                        (click)="toggleFavorite($event)"></fa-icon>
                    Save
                </button>
            </div>
            <div class="image-tag" *ngIf="projectDetails?.isTheProjectReraApproved === true">Rera</div>
        </div>
        <div class="thumbnail-list" *ngIf="images && images.length > 0">
            <div class="thumbnail" *ngFor="let image of images" (click)="selectImage(image)">
                <img [src]="image?.objectUrl" alt="Thumbnail">
            </div>
        </div>
    </div>
  </div>

  <div class="col-md-6 pull-right">
    <div class="property-right" [ngClass]="{'property-right1': isAdmin === true}">

    <div class="row">
        <div class="col-md-12 col-sm-12">
            <ng-container *ngIf="isMobile">
                <div class="main-image" *ngIf="selectedImage && selectedImage.length > 0">
                <img [src]="selectedImage" alt="Selected Image">
            </div>
            <div class="thumbnail-list" *ngIf="images && images.length > 0">
                <div class="thumbnail" *ngFor="let image of images" (click)="selectImage(image)">
                    <img [src]="image?.objectUrl" alt="Thumbnail">
                </div>
            </div>
            </ng-container>
           
            <div class="amenities-top" *ngIf="amenities && amenities.length > 0">
                <ng-container >
                        <p *ngFor="let amenity of visibleAmenities; let i = index">
                            <img [src]="amenity?.icon" class="feature-icon" alt="{{amenity.name}}">
                            <span  class="amenities-text" ngbTooltip="{{amenity?.name}}">{{amenity?.name}}</span> 
                            <span class="amenities-divider"  *ngIf="i === 0"></span>
                        </p>
                        <button class="more-button" *ngIf="showMoreAmenities?.length>2"  (click)="scrollToAmenities()">+ {{showMoreAmenities?.length}} More</button>
                  </ng-container>
            </div>
        </div>
        <div class="main-details-head">

        
        <div class="col-md-4 main-details pull-left">
            <div class="details">
                <p>Project</p>
                <h5>{{projectDetails?.projectName}}</h5>
            </div>
        </div>
        <div class="col-md-4 main-details pull-left" *ngIf="projectDetails?.projectBy">
            <div class="details">
                <p>Developed By</p>
                <h5>{{projectDetails?.projectBy}}</h5>
            </div>
        </div>
        <div class="col-md-4 main-details pull-left">
            <div class="details">
                <p>Category</p>
                <h5>{{projectDetails?.categoryName}}</h5>
            </div>
        </div>
        <div class="col-md-4 main-details pull-left">
            <div class="details" *ngIf="units.length>0">
                <p>Price</p>
                <h5>₹{{minimumPrice}}-{{maximumPrice}}</h5>
            </div>
            <div class="details" *ngIf="units.length===0">
                <p>Price</p>
                <h5>₹{{minimumPrice}}/{{projectDetails?.minimumSize?.unitType}}</h5>
            </div>
        </div>
        <div class="col-md-4 main-details pull-left">
            <div class="details" *ngIf="units.length>0">
                <p>Size</p>
                <h5>{{projectDetails?.minimumSize?.value}} - {{projectDetails?.maximumSize?.value}} {{projectDetails?.maximumSize?.unitType}} </h5>
            </div>
            <div class="details" *ngIf="units.length===0">
                <p>Size</p>
                <h5>{{projectDetails?.minimumSize?.value}}  {{projectDetails?.maximumSize?.unitType}} </h5>
            </div>
        </div>
        <div class="col-md-4 main-details pull-left"  *ngIf="projectDetails?.towers[0]?.value>0">
            <div class="details">
                <p>Floors</p>
                <h5>{{ getMaxFloors(projectDetails) }}</h5>
            </div>
        </div>
        <div class="col-md-4 main-details pull-left"  *ngIf="projectDetails?.numberOfTowers !== null">
            <div class="details">
                <p>Towers</p>
                <h5> {{ getTowers(projectDetails) }}</h5>
            </div>
        </div>
    </div>
    </div>
    
    <div class="row">
        <div class="col-md-12 price">
            <!-- <div class="price-number" *ngIf="units?.length>0">₹{{minimumPrice}}-{{maximumPrice}}</div> -->
            <!-- <span class="per-feet">₹2500/ sq.ft</span> -->
            <div class="download">
                <div class="col-md-4 pull-right" *ngIf="!isAdmin">
                    <div class="button-card">
                        <ng-container>
                            <app-common-button buttonClass="smallbuttoncancel" buttonLabel="Enquiry Now"
                                (clickEvent)="openEnquiry()"></app-common-button>
                        </ng-container>
                    </div>
                </div>
                <div class="col-md-4 pull-left"> 
                    <div class="button-card" [ngClass]="{'button-card1': isAdmin === true}">
                    <app-common-button buttonClass="roundedRegularButton" buttonLabel="Download Brochure"
                    [ngbTooltip]="'Download Brochure'" (click)="downloadAllBrochures('brochure','brochure')"
                    *ngIf="projectDetails?.brochure?.length"></app-common-button>
                </div>
                </div>
                <div class="view-doc col-md-4 pull-left" [ngClass]="{'view-document pull-right': isAdmin === true}" *ngIf="(projectDetails?.brochure?.length > 0 || projectDetails?.buildingPlan?.length > 0 || projectDetails?.floorPlan?.length > 0)">
                    <div class="button-card" [ngClass]="{'button-card2': isAdmin === true}">
                    <p class="view" [ngClass]="{'view-document-scroll': isAdmin === true}"
                        (click)="scrollToDocuments()">View all Documents</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
      <!-- <div class="col-8">
          <div class="property-name" *ngIf="projectDetails">
              <div [ngClass]="{'property-details': isAdmin !== true}">
                 
                  <p class="property-name-heading">{{projectDetails?.projectName}}</p>
                  
                  <p>Project By</p>
                  <span class="property-name-by" *ngIf="projectDetails?.projectBy">by</span> <span
                      class="property-name-by-name">{{projectDetails?.projectBy}}</span>
                  <p class="property-address">{{projectDetails?.formattedAddress}}</p>
                  <p class="property-type">{{projectDetails?.categoryName}}</p>
              </div>
              <div class="row" *ngIf="headerImage && headerImage.length > 0">
                  <div class="col-md-5" *ngFor="let unit of units" [ngClass]="{'details-card': isAdmin !== true}">
                      <div class="card enquiry" [ngClass]="{'property-details': isAdmin !== true}">
                          <div class="enquiry-header">
                              <p class="card-title">{{unit?.unitName}}</p>
                          </div>
                          <div class="card-text-main">
                              <p class="card-text">
                                  {{ unit?.unitSize?.minSize }} {{ unit?.unitSize?.unitType }} -
                                  {{ unit?.unitSize?.maxSize }} {{ unit?.unitSize?.unitType }}
                              </p>

                              <p class="card-text">{{unit?.unitFacing}}</p>
                              <p class="card-amount">
                                  {{ priceFormatterService.formatPrice(unit?.unitPrice?.minPrice) }} -
                                  {{ priceFormatterService.formatPrice(unit?.unitPrice?.maxPrice) }}
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> -->
   
  </div>

</div>
</div>
</div>

<div class="row more-details-container" [ngClass]="{'more-details-container1': isAdmin === true}">
    <div class="col-md-12">
        <div class="container">
        <div class="more-details pull-left">
            <h2>More Details</h2>
            <div class="sale-cards-container" *ngIf="units.length ===0"
            [ngClass]="{'sale-cards-container-user': isAdmin !== true}">
            <div class="sale-card">
                <p class="sale-text">Asset Value</p>
                <p class="sale-text">&#8377; {{totalAssetValue}}</p>
            </div>
            <div class="size-card">
                <p class="sale-text">SIZE</p>
                <p class="sale-text">{{projectDetails?.size?.value}} {{projectDetails?.size?.unitType}}</p>
            </div>
            </div>
            <div class="row" *ngIf="headerImage && headerImage.length > 0">
                <div class="col-3 col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let unit of units" [ngClass]="{'details-card': isAdmin !== true, 'me-md-3': true,'me-3': true}">
                    <div class="card enquiry" [ngClass]="{'property-details': isAdmin !== true}">
                        <div class="enquiry-header">
                            <p class="card-title">{{unit?.unitName}}</p>
                        </div>
                        <div class="card-text-main">
                            <p class="card-text">
                                {{ unit?.unitSize?.minSize }} {{ unit?.unitSize?.unitType }} -
                                {{ unit?.unitSize?.maxSize }} {{ unit?.unitSize?.unitType }}
                            </p>

                            <p class="card-text">{{unit?.unitFacing}}</p>
                            <p class="card-amount">
                                {{ priceFormatterService.formatPrice(unit?.unitPrice?.minPrice) }} -
                                {{ priceFormatterService.formatPrice(unit?.unitPrice?.maxPrice) }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2 pull-left mt-2">
                <div class="more-details-description-title">
                    <p><strong><span class="more-details-text">Description</span></strong></p>
                </div>
            </div>
            <div class="col-md-10 pull-right mt-2">
                <div class="more-details-description-text">
                    <p><span class="more-details-description"> {{projectDetails?.description}}</span></p>
                </div>
            </div>
        </div>
        
    </div>
    </div>
</div>
<!-- <div class="row">
    <div class="col-md-12">
        <div class="container">
        <div class="more-details">
            <h2>About Project</h2>
          <div class="col-md-12 pull-left">
            <div class="about-project">
                <div class="col-md-2 pull-left">
                    <div class="about-project-image" *ngIf="images && images.length > 0">
                        <img [src]="images[0]?.objectUrl" alt="Thumbnail">
                    </div>
                </div>
                <div class="col-md-3 pull-left">
                    <div class="about-project-text">
                        <h2>{{projectDetails?.projectName}}</h2>
                        <p>by {{projectDetails?.projectBy}}</p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div  class="about-project-text">
                        <p>Price</p>
                        <h2>₹{{minimumPrice}}-{{maximumPrice}}</h2>
                    </div>
                </div>
            </div>
          </div>
          <div class="col-md-12 mt-2 pull-left">
            <div class="about-project">
                <div class="col-md-2 pull-left">
                    <div class="about-project-text">
                        <h2>{{projectDetails?.projectName}}</h2>
                        <p>by {{projectDetails?.projectBy}}</p>
                    </div>
                </div>
                <div class="col-md-3 pull-left">
                    <div class="about-project-text">
                        <h2>{{projectDetails?.projectName}}</h2>
                        <p>by {{projectDetails?.projectBy}}</p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div  class="about-project-text">
                        <p>Price</p>
                        <h2>₹{{minimumPrice}}-{{maximumPrice}}</h2>
                    </div>
                </div>
            </div>
          </div>
        </div>
        
    </div>
    </div>
</div> -->
  <!-- <div class="description-heading" *ngIf="projectDetails">
      <p class="description-heading-text">{{projectDetails?.projectName}}</p>
      <p class="description-text">
          {{projectDetails?.description}}
      </p>
  </div>
  <div class="amenities-container" *ngIf="projectDetails?.numberOfTowers !== null">
      <div class="amenity">
          <div class="amenity-text">
              {{ getTowers(projectDetails) }}
          </div>
          <div class="amenity-icon">
              <fa-icon [icon]="faTower" class="favorite-icon"></fa-icon>
          </div>
      </div>
      <div class="divider"></div>
      <div class="amenity">
          <div class="amenity-text">
              {{ getMaxFloors(projectDetails) }}
          </div>
          <div class="amenity-icon">
              <fa-icon [icon]="faFloor" class="favorite-icon"></fa-icon>
          </div>
      </div>
  </div> -->
  <div class="floor-plan" *ngIf="floorPlan.length > 0" [ngClass]="{'floor-plan1': isAdmin === true}">
      <br>
      <p class="floor-plan-text">FLOOR PLAN</p>
      <ngb-carousel #carousel [interval]="0" [showNavigationIndicators]="false"
          [showNavigationArrows]="floorPlan && floorPlan.length > 1">
          <ng-template *ngFor="let image of floorPlan" ngbSlide>
              <div class="floor-image">
                  <img src="{{image?.objectUrl}}" alt="...">
              </div>
          </ng-template>
      </ngb-carousel>
      <!-- <div class="floor-plan-text-sub">
          <p>To arrive at Viridā in Kareempur, situated around 74 kilometers from Hyderabad's Financial District in
              the western suburbs, follow the path through Shankarpally, which is roughly 40 kilometers away, and
              continue for approximately 15 kilometers towards Mominpet. This route provides picturesque vistas and
              typically takes about 90 minutes by road. For precise directions, refer to online maps or navigation
              apps.
          </p>
      </div> -->
  </div>
  <div class="background-container" *ngIf="amenities && amenities.length > 0" #amenitiesSection [ngClass]="{'background-container1': isAdmin === true}">
      <div class="content-overlay">
          <p class="main-title">Unique Attributes & Characteristics</p>
          <!-- <p class="description">Vīridā blends rustic charm with modern comfort. It's carefully designed to create
              a peaceful place where nature meets sophistication. Experience amenities tailored to today’s
              families without compromising their lifestyle.</p> -->
          <div class="features-container">
              <div class="features-list">
                  <ng-container *ngIf="amenities && amenities.length > 0">
                      <div class="feature-item" *ngFor="let amenity of amenities">
                          <img [src]="amenity?.icon" class="feature-icon" alt="{{amenity.name}}">
                          <span>{{amenity?.name}}</span>
                      </div>
                  </ng-container>
              </div>
          </div>
      </div>
  </div>
  <div class="row" *ngIf="projectDetails?.formattedAddress">
      <div class="location">
          <p class="location-heading" [ngClass]="{'location-heading1': isAdmin === true}">LOCATION</p>
      </div>
      <div class="col-md-7 col-sm-12">
          <div class="location-text" [ngClass]="{'location-text1': isAdmin === true}">
              <p>{{projectDetails?.formattedAddress}}</p>
          </div>
          <!-- <div class="location-description">
              <p>Discover Kareempur, nestled near Mominpet in Greater Hyderabad. This unique blend of natural beauty
                  and growth opportunities is just a stone’s throw away from Kotpally Reservoir. Imagine serene
                  surroundings, lush landscapes, and abundant wildlife, all accompanied by a refreshing breeze
              </p>
          </div> -->
          <div class="location-container" *ngIf="vicinity?.distance">
              <p>Nearby locations</p>
              <div class="locations">
                  <div *ngFor="let vicinity of vicinity">
                      <p class="location-p">+ {{vicinity?.facility}} {{vicinity?.distance}}km</p>
                  </div>
              </div>
          </div>

      </div>
      <div class="col-5">
          <div [ngClass]="{'card-maps': isAdmin !== true}" class="maps">
              <iframe [src]="mapUrl" width="400" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade">
              </iframe>
          </div>
      </div>
  </div>
  <div *ngIf="video && video.length > 0">
      <div class="construction" [ngClass]="{'construction1': isAdmin === true}">
          <p>CONSTRUCTION VIDEOS</p>
      </div>
      <div class="construction-sub" [ngClass]="{'construction-sub1': isAdmin === true}">
          <ngb-carousel #carousel [interval]="0" [showNavigationIndicators]="false"
              [showNavigationArrows]="video && video.length > 1">
              <ng-template *ngFor="let video of video" ngbSlide>
                  <div class="video-slide">
                      <iframe width="420" height="345" [src]="video?.urlSafe" style="border:0;" allowfullscreen=""
                          loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
              </ng-template>
          </ngb-carousel>
      </div>
  </div>
  <div class="construction" *ngIf="projectDetails?.brochure?.length || projectDetails?.buildingPlan?.length || projectDetails?.floorPlan?.length" >
      <p>DOCUMENTS</p>
  </div>
  <br>
  <div [ngClass]="{'card-m': isAdmin !== true}" class="card-container" #documentsSection>
      <div class="card-info" *ngIf="projectDetails?.brochure?.length">
          <p class="construction-text">Brochure</p>
          <fa-icon [icon]="faDownload" class="construction-icon" [ngbTooltip]="'Download Brochure'"
              (click)="downloadAllBrochures('brochure')"></fa-icon>
      </div>
      <div class="card-info" *ngIf="projectDetails?.buildingPlan?.length">
          <p class="construction-text">Building Plan</p>
          <fa-icon [icon]="faDownload" class="construction-icon" [ngbTooltip]="'Download Building Plan'"
              (click)="downloadAllBrochures('buildingPlan','buildingPlan')"></fa-icon>
      </div>
      <div class="card-info" *ngIf="projectDetails?.floorPlan?.length">
          <p class="construction-text">Floor Plan</p>
          <fa-icon [icon]="faDownload" class="construction-icon" [ngbTooltip]="'Download Floor Plan'"
              (click)="downloadAllBrochures('floorPlan','floorPlan')"></fa-icon>
      </div>
  </div>
</div>
</div>
</div>

<app-enquiry-popup [propertyId]="selectedPropertyId" [isEnquiry]="isEnquiry"
  (closeModal)="closeEnquiry()"></app-enquiry-popup>

<div *ngIf="!checkLoginStatus">
  <app-login-popup [isVisible]="isLoginModalVisible" (closeModal)="closeProjectModal()"></app-login-popup>
</div>

<ng-template #imageModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Image Preview</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <img [src]="modalImage" alt="Full View" class="img-fluid">
    </div>
</ng-template>
