<div class="navbar-bg m-3">
  <div class="card-body">
    <div class="head">
      <nav class="navbar navbar-expand-lg navbar-light bg-white rounded-pill shadow-sm py-3 px-5">
        <a class="navbar-brand" (click)="home()">
          <img src="../../assets/images/Property box logo with out back ground (1).png" alt="Logo" class="logo">
        </a>
        <button class="navbar-toggler" type="button" (click)="toggleOffCanvas()">
        <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
          <ul class="navbar-nav mr-3 rounded-pill bg-light px-3">
            <img src="../../assets/images/map (1).png" class="dollar-logo">
            <li class="nav-item">
              <a class="nav-link" (click)="mapView()" >Map View</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)" (click)=properties()>Properties</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="javascript:void(0)" (click)="scrollToSection('featured')">Featured Properties</a>
            </li>
          </ul>
          <ul *ngIf="!checkLoginStatus" class="navbar-nav  m-2 rounded-pill bg-light px-3 border border-dark">
            <li class="nav-item">
              <a class="nav-link contact" (click)="logIn()">Login</a>
            </li>
          </ul>
          <div class="profile-container" *ngIf="checkLoginStatus">
            <fa-icon [icon]="userCircleIcon" style="color:#fbb416; font-size: 40px; cursor: pointer;margin-left: 10px;"
            class="profile-dropdown-menu"  (click)="toggleMenu()"></fa-icon>
            <div *ngIf="menuVisible" class="profile-menu">
              <a (click)="profile()">Profile</a>
              <a (click)="logOut()">Logout</a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>

<!-- Overlay panel for mobile and tablet views -->
<div class="overlay" *ngIf="isOffCanvasOpen" (click)="toggleOffCanvas()"></div>
<div class="off-canvas width-350" [ngClass]="{'show': isOffCanvasOpen}">
  <div class="offCanvas__container">
    <div class="offCanvas__header">
      <div class="offCanvas__title">
        <a class="navbar-brand sideLogo">
          <img src="../../assets/images/Property box Trasnparent.png" alt="Logo" class="logo">
        </a>
      </div>
      <div class="offCanvas__Iconscontainer">
        <div class="Offcanvas__cancelIcon" (click)="toggleOffCanvas()">
          <img src="../../../assets/images/icons8-cancel.svg" alt="Close Menu">
        </div>
      </div>
    </div>
    <div class="offCanvas__contentWrapper">
      <ul class="offCanvas__menu">
        <li (click)="mapView(); toggleOffCanvas()">Map View</li>
        <li (click)="scrollToSection('about'); toggleOffCanvas()">About Us</li>
        <li (click)="scrollToSection('featured'); toggleOffCanvas()">Featured Properties</li>
        <li *ngIf="!checkLoginStatus" (click)="logIn(); toggleOffCanvas()">Login</li>
        <li *ngIf="checkLoginStatus" (click)="profile(); toggleOffCanvas()">Profile</li>
        <li *ngIf="checkLoginStatus" (click)="logOut(); toggleOffCanvas()">Logout</li>
      </ul>
    </div>
  </div>
</div>
